import { graphql } from "gatsby";
import React from "react";
import Layout from "../components/layout/Layout";
export default ({ data, location }) => {
    const getContentHTML = () => {
        const contentHTML = data.contentfulPolicy.policyContent.childMarkdownRemark.html;
        if (data.contentfulPolicy.policyVersionsContent?.childMarkdownRemark?.html) {
            return `${contentHTML}\n${data.contentfulPolicy.policyVersionsContent.childMarkdownRemark.html}`;
        }
        return contentHTML;
    }
    
    return (
        <Layout title={data.contentfulPolicy.policyName} location={location}>
            <main id="content">
                <section className="section">
                    <div className="container content law-content">
                        <div className="columns">
                            <div className="column is-9 is-offset-2" dangerouslySetInnerHTML={{ __html: getContentHTML() }} />
                        </div>
                    </div>
                </section>
            </main>
        </Layout>
    )
}

export const query = graphql`
query ($contentful_id: String!) {
    contentfulPolicy (contentful_id: { eq: $contentful_id }) {
        policyName
        policyContent {
            childMarkdownRemark {
                html
            }
        }
        policyVersionsContent {
            childMarkdownRemark {
              html
            }
        }
    }
}
`